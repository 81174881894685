import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Input from "components/Form/Input/Input";
import Button, { ButtonGroup } from "components/Form/Button/Button";

const NewClassModal = (props) => {
	const [classDesc, setClassDesc] = useState("");
	const { t } = useTranslation();

	const handleSubmit = () => {
		props.onSubmit(classDesc);
		setClassDesc("");
		props.onClose();
	};

	return (
		<Modal
			title={t("Create new class")}
			className="w-[640px]"
			onClose={props.onClose}
			onSubmit={props.onSubmit}
		>
			<form className="flex flex-col gap-4 w-full" onSubmit={handleSubmit}>
				<p className="message__note">
					<i className="ri-book-open-line"></i>
					{t(
						"By default the newly created class is inactive and not public. To change this you can edit the class after it has been created.",
					)}
				</p>
				<p className="mt-4">{t("Enter a title for the new class.")}</p>
				<Input
					id="class-id"
					className="mt-4 max-w-full"
					label={t("Class title")}
					type="text"
					name="class-id"
					value={classDesc}
					onChange={(e) => setClassDesc(e.target.value)}
					autoFocus
					required
				/>
				<ButtonGroup>
					<Button className="btn-primary">{t("Create Class")}</Button>
				</ButtonGroup>
			</form>
		</Modal>
	);
};

export default NewClassModal;
