import React from "react";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useHelp } from "context/HelpContext";

import Button from "components/Form/Button/Button";

import "./HelpSidebar.scss";

function flatten(children) {
	return children.reduce((text, child) => {
		return typeof child === "string"
			? text + child
			: text + flatten(React.Children.toArray(child.props.children));
	}, "");
}

/**
 * custom heading renderer
 *
 * add id to heading based on the heading text
 */
const MarkdownHeading = ({ children, node }) => {
	const text = flatten(React.Children.toArray(children));
	const slug = text
		.toLowerCase()
		.replace(/\W/g, "-") // replace non-word characters with hyphens
		.replace(/-+$/, ""); // remove trailing hyphens
	const TagName = node.tagName;

	return <TagName id={slug}>{children}</TagName>;
};

/**
 * custom Anchor
 *
 * anchor links              [#section](#section)
 * internal links to topics  [Dashboard](topic:Dashboard)
 * external links            [Google](https://google.com)
 * internal links to views   [Dashboard](/dashboard)
 */
const MarkdownAnchor = (props) => {
	const { setTopic } = useHelp();
	const { href, children, onClick } = props;

	/**
	 * custom click handler
	 *
	 * prevent changing the URL for internal anchors
	 */
	const handleClick = (event) => {
		const anchor = event.target.closest("a");

		if (anchor && anchor.getAttribute("href")?.startsWith("#")) {
			event.preventDefault();
			const id = anchor.getAttribute("href").slice(1);
			const targetElement = document.getElementById(id);
			if (targetElement) {
				targetElement.scrollIntoView({ behavior: "smooth" });
			}
		}

		if (anchor && anchor.getAttribute("href")?.startsWith("@")) {
			event.preventDefault();
			const topic = anchor.getAttribute("href").slice(1);
			console.log(topic);
			setTopic(topic);
		}
	};

	if (href.startsWith("#")) {
		return (
			<a href={href} onClick={handleClick}>
				{children}
			</a>
		);
	}

	if (href.startsWith("@")) {
		return (
			<a href={href} onClick={handleClick}>
				{children}
			</a>
		);
	}

	if (href.match(/^(https?:)?\/\//)) {
		return (
			<a href={href} onClick={handleClick}>
				{children} <i className="ri-external-link-line"></i>
			</a>
		);
	}

	return (
		<Link className="btn" to={href} onClick={handleClick}>
			{children}
		</Link>
	);
};

const HelpSidebar = ({ onClose }) => {
	const { heading, content } = useHelp();
	const { t } = useTranslation();

	return (
		<div className="help-sidebar ms-auto shrink-0 w-[320px] flex flex-col overflow-hidden border-l text-slate-700">
			<div className="py-3 px-4 flex gap-2 items-center relative border-b">
				<h2 className="m-0 text-sm leading-none">{t("Help")}</h2>
				<Button className="btn-close" onClick={onClose}>
					<i className="ri-close-line text-2xl"></i>
				</Button>
			</div>
			<div
				id="help-content"
				className="content px-4 overflow-auto scroll-smooth shadow-inner"
			>
				<h3 className="px-4 py-2 mb-4 -mx-4 bg-slate-100 text-sm font-normal uppercase text-slate-500">
					{t(heading)}
				</h3>
				<Markdown components={{ h2: MarkdownHeading, a: MarkdownAnchor }}>
					{content}
				</Markdown>
				<div className="m-8 text-center">
					<a
						href="#top"
						className="btn-back-to-top"
						onClick={(event) => {
							event.preventDefault();
							document.querySelector("#help-content").scrollTo({ top: 0 });
						}}
					>
						<i className="ri-arrow-up-s-line"></i>
						{t("Back to top")}
					</a>
				</div>
			</div>
		</div>
	);
};

export default HelpSidebar;
