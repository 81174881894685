import { Switch } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

const Toggle = ({
  id,
  className,
  label,
  title = "",
  checked,
  onChange: handleChange,
  ...rest
}) => {
  return (
    <Switch.Group>
      <div className={twMerge("toggle-container flex items-center", className)}>
        <Switch
          className={`relative inline-flex h-[1.4rem] w-[2.6rem] shrink-0 items-center rounded-full ${
            checked ? "bg-green-600" : "bg-slate-300"
          }`}
          checked={checked}
          title={title}
          onChange={(value) => {
            handleChange && handleChange(value);
          }}
        >
          <span
            className={`inline-block h-[1rem] w-[1rem] rounded-full bg-white transition-transform transform duration-600 ${
              checked ? "translate-x-[1.4rem]" : "translate-x-[0.2rem]"
            }`}
          />
        </Switch>
        {label && (
          <Switch.Label className="pl-[.5em] cursor-pointer">
            {label}
          </Switch.Label>
        )}
      </div>
    </Switch.Group>
  );
};

export default Toggle;
